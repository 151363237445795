<template>
  <div class="app">
    <el-card class="box-card">

        <div class="header">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-popover
                placement="top"
                width="160"
                v-model="delAllStatus"
                style="margin-right: 8px;"
              >
                <p>您確認要刪除嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="delAll">確定</el-button>
                </div>
                <el-button size="small" type="danger" slot="reference">
                  <i class="fa fa-trash"></i> 批量刪除
                </el-button>
              </el-popover>

              <el-button
                size="small"
                type="info"
                @click="$refs.RoomTable.clearSelection()"
                style="margin-right: 8px;"
              >取消選擇</el-button>

              <el-button
                size="small"
                type="success"
                @click="handleCreate('create')"
                style="margin-right: 8px;"
              ><i class="el-icon-plus" style="font-weight: 900;"></i> 添加規則組</el-button>

            </el-col>

            <el-col :span="12">
              <el-input placeholder="填寫查詢內容" size="small" v-model="query" class="employees-input">
                <el-button slot="append" @click="queryBin">
                  <i class="fa fa-search"></i>
                </el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>

      <el-row :md="24">
        <el-col :md="10" :lg="15" style="padding-right:10px;">
          <!-- 列表 -->
          <el-table
          ref="RoomTable"
          :data="data.list"
          :height="data.total?clientHeight:'100'"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%;">
          <el-table-column fixed type="selection" width="40"></el-table-column>
          <el-table-column  prop="id" label="# 編號" width="80"></el-table-column>
          <el-table-column  prop="title" label="規則名稱"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-link type="primary" @click="edit(scope.row)" >編輯</el-link> |
                <el-link type="danger" @click="del(scope.row.id)" :disabled="scope.row.id !=1?false:true">删除</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pager">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="data.pageSize"
            :total="data.total"
          ></el-pagination>
        </div>
        </el-col>

        <el-col :md="14" :lg="9">
          <el-card class="box-card" shadow="never">
            <div slot="header">
              <span>{{statusTitle}}</span>

              <el-button
                type="primary"
                :loading="formLoading"
                size="medium"
                style="float: right; margin-bottom: 12px;"
              @click="createOrUpdate">確定</el-button>
            </div>

            <el-form :model="form" :rules="rules" ref="form" label-width="80px">

              <el-form-item label="組名稱" prop="title">
                <el-input v-model="form.title" placeholder="填寫組名稱" :clearable="true" />
              </el-form-item>


                <el-form-item label="門店授權" prop="bookingType">
                  <el-select v-model="form.storeAuth" multiple placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in storeList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>

              <el-form-item label="Team狀態" prop="bookingType">
                <el-select v-model="form.teamType" placeholder="請選擇" style="width: 100%;">
                  <el-option
                    v-for="item in teamList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>



              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="排序" prop="sort">
                  <el-input-number
                    v-model="form.sort"
                    :min="0"
                    :max="255"
                    style="width: 100%;"
                    controls-position="right" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="狀態">
                    <el-switch v-model="form.status" active-value="1" inactive-value="0"></el-switch>
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="hr"></div>

              <el-row :gutter="20">
                <el-col :span="12">
                    <el-button-group>
                      <el-button icon="el-icon-circle-plus-outline" @click="checkedNodes(true)">展開</el-button>
                      <el-button icon="el-icon-remove-outline" @click="checkedNodes(false)">收起</el-button>
                    </el-button-group>
                </el-col>
                <el-col :span="12">
                    <el-input
                      v-model="filterText"
                      placeholder="填寫關鍵詞進行鍋爐"
                      prefix-icon="el-icon-search"
                      style="width: 240px;"
                      :clearable="true"
                    ></el-input>
                </el-col>
              </el-row>

              <div style="margin-top: 12px;">
                <el-tree
                  v-if="hackReset"
                  :data="menuIsNav"
                  class="tree-scroll"
                  node-key="id"
                  :props="treeProps"
                  :highlight-current="true"
                  :filter-node-method="filterNode"
                  :default-expand-all="isExpandAll"
                  :default-checked-keys="form.treeData"
                  show-checkbox
                  ref="tree">
                  <span class="custom-tree-node action" slot-scope="{ node, data }">
                    <span class="brother-showing">
                      <i :class="`iconfont ${data.icon}`"></i>
                      {{ node.label }} <i class="el-icon-paperclip"> {{ data.path }}</i>
                    </span>
                  </span>
                </el-tree>
              </div>

            </el-form>
          </el-card>
        </el-col>
      </el-row>

    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {ApiGetMenuList} from '@/utils/http'
export default {
  computed: {
    ...mapState({
      iconList: (state) => state.icon.list,
      menu: (state) => state.tab.menu
    }),
    // 菜单过滤
    menuIsNav () {
      let newList = [];
      let list = this.menu;
      for (let o in list) {
        if (list[o].children[0]) {
          let children = list[o].children;
          let newchildren = [];
          for (let i in children) {
            if(parseInt(children[i].isNav) == 1){
              let children2 = children[i].children;
              let newchildren2 = [];
              for (let j in children2) {
                if(parseInt(children2[j].isNav) == 1){
                  newchildren2.push({
                    ...children2[j],
                  });
                }
              }
              newchildren.push({
                ...children[i],
                children: newchildren2,
              });
            }
          }
          if(parseInt(list[o].isNav) == 1) {
            newList.push({
              ...list[o],
              children: newchildren,
            });
          }
        } else {
          if(parseInt(list[o].isNav) == 1) {
            newList.push({
              ...list[o]
            });
          }
        }
      }
      return newList
    },
  },
  data() {
    return {
      loading:false,
      delAllStatus: false,
      clientHeight:'600',

      query:'',
      data:{
          list:[],
          pageSize:5,
          total:0,
      },
      page: 1,
      form: {
        id: 0,
        menuAuth: [],
        treeData: [],
        storeAuth: [],
        teamType:'0',
        title: '',
        status: '1',
        sort: 0,
        remark:'',
      },
      formStatus: "create",
      formLoading: false,
      statusTitle: "添加规则",
      filterText: "",



      //iconList:[],
      storeList:[],
      teamList:[
        {
          value:'0',
          label:'不選擇'
        },
        {
          value:'1',
          label:'Call Team'
        },
        {
          value:'2',
          label:'Road Show'
        },
      ],

      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      menuStatus: "收起",

      isExpandAll: false,
      hackReset: true,
      expanded: [],

      treeModule: {},
      treeProps: {
        label: "label",
        children: "children",
      },
      cascaderProps: {
        value: "value",
        label: "label",
        children: "children",
        checkStrictly: true,
        emitPath: false,
      },
      auth: {
        add: false,
        del: false,
        set: false,
        status: false,
        move: false,
      },
      rules: {
        title: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
          {
            max: 32,
            message: "长度不能大于 32 个字符",
            trigger: "blur",
          },
        ],
        ruleId: [
          {
            required: true,
            message: "请选择规则组",
            trigger: "change",
          }
        ],
        sort: [
          {
            type: "number",
            message: "必须为数字值",
            trigger: "blur",
          },
        ],
        remark: [
          {
            max: 255,
            message: "长度不能大于 255 个字符",
            trigger: "blur",
          },
        ],
      },

    };
  },
  mounted() {
    this.getRuleList()
    this.getStoreList()

  },
  methods: {
    // 获取門店列表
    getStoreList() {
      let that = this;
      this.$http.GET(
        "/v1/booking/getStoreList",
        function (ret) {
          if (ret.errcode == 0) {
            let list = ret.data.list
            for(let i in list){
              that.storeList.push({
                id:list[i].id,
                value:list[i].id,
                label:list[i].title,
              })
            }
          } else if (ret.errcode >= 1) {
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 获取权限规则列表
    getRuleList(page){
      let that = this;
      let param = that.$atk.json_to_url({
        page: page || parseInt(that.page),
        query: that.query,
      });
      that.loading = true;
      this.$http.GET(
        "/v1/auth/getRuleList?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            let data = ret.data || [];
            that.data = {
              ...data
            };
            that.loading = false;
          } else if (ret.errcode == 1) {
            that.loading = false;
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.loading = false;
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getRuleList(val);
    },
    // 重置表单
    resetForm() {
      this.form = {
        id: 0,
        menuAuth: [],
        treeData: [],
        storeAuth: [],
        teamType:'0',
        title: '',
        status: '1',
        sort: 0,
        remark:'',
      };
      this.checkedNodes()
    },
    // 添加菜单表单初始化
    handleCreate(status) {
      this.statusTitle = "添加规则";
      this.resetForm();
      this.resetElements(status);
    },
    // 点击树节点事件
    edit(data) {
      this.statusTitle = "编辑规则";

      this.resetForm();
      this.resetElements("update");
      this.form = {
        ...data,
        menuAuth: JSON.parse(data.menuAuth),
        treeData: JSON.parse(data.treeData),
        storeAuth: JSON.parse(data.storeAuth),
        status: data.status.toString(),
        teamType:data.teamType.toString()
      };
    },
    // 重置元素
    resetElements(val = "create") {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      this.formStatus = val;
      this.formLoading = false;
    },
    // 选择用户
    handleSelectionChange(val) {
      this.multipleSelection = val; //.filter(val => val.id!=1)
      let row = val.filter((val) => val.id == 1);
      this.$refs.RoomTable.toggleRowSelection(row[0], false);
    },
    // 删除已选择用户
    delAll() {
      let that = this;
      let length = that.multipleSelection.length;
      if (length <= 0) {
        that.$notify({
          title: "回收提示",
          message: "请勾选回收用户",
          type: "warning",
        });
        that.delAllStatus = false;
        return;
      }
      let ids = [];
      for (let i in that.multipleSelection) {
        ids[i] = that.multipleSelection[i].id;
      }
      that.remove(ids);
      that.delAllStatus = false;
    },
    // 删除
    del(ids){
      let that = this
      this.$confirm("确定要执行该操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
          that.remove(ids);
      }).catch(() => {});
    },
    // 查询
    queryBin() {
      let that = this;
      if (!that.query) {
        that.$message({
          message: "请输入查询内容",
          type: "warning",
        });
        return;
      }
      this.getList();
    },
    // 添加/更新菜单
    createOrUpdate() {
      let that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          //that.formLoading = true;
          let form = {
            ...that.form,
            menuAuth: JSON.stringify(that.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())),
            treeData: JSON.stringify(that.$refs.tree.getCheckedKeys()),
            storeAuth: JSON.stringify(that.form.storeAuth),
            status:parseInt(that.form.status),
            teamType:parseInt(that.form.teamType)
          }


          that.$http.POST('/v1/auth/addOrEditUserRule',form,function (ret) {
              if (ret.errcode == 0) {
                that.$emit("refresh");
                that.getRuleList()
                that.resetForm()
                that.$message.success(ret.errmsg);
                that.formLoading = false;
              } else if (ret.errcode >= 1) {
                that.formLoading = false;
                that.$message({
                  message: ret.errmsg,
                  type: 'warning'
                })
              }
          },(err)=> {
              that.formLoading = false;
              that.$notify({
                title: '警告',
                message: err,
                type: 'warning'
              })
            }
          )
        }
      });
    },
    // 删除列表用户
    deleteItem(item, list) {
      for (let key in this.data.list) {
        if (list[key].id === item) {
          list.splice(key, 1);
        }
      }
    },
    // 删除菜单
    remove(ids) {
      let that = this
      that.$http.DELETE('/v1/auth/delUserRule?ids='+ids,function (ret) {
          if (ret.errcode == 0) {
            if(ids[0]){
              for (let key in ids) {
                that.deleteItem(ids[key],that.data.list);
              }
            } else{
              that.deleteItem(ids,that.data.list);
            }
            that.handleCreate("create");
            that.$message.success(ret.errmsg);
          } else if (ret.errcode >= 1) {
            that.$message({
              message: ret.errmsg,
              type: 'warning'
            })
          }
      },(err)=> {
          that.$notify({
            title: '警告',
            message: err,
            type: 'warning'
          })
        }
      )
    },
    // 过滤菜单
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    // 展开或收起节点
    checkedNodes(isExpand) {
      this.filterText = "";
      this.hackReset = false;
      this.$nextTick(() => {
        this.isExpandAll = isExpand;
        this.hackReset = true;
      });
    },
    // 追加菜单
    handleAppend(key) {
      this.handleCreate("create");
      this.$refs.tree.setCurrentKey(key);
      this.form.parentId = key;
    },
    // 点击树节点事件
    handleNodeClick(data) {
      this.statusTitle = "编辑用户组";

      this.resetForm();
      this.resetElements("update");

      this.form = {
        ...data,
        linkType: data.linkType.toString(),
        isNav: data.isNav.toString(),
      };
    },

  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>
<style scoped lang="scss">
.hr{
  width: 100%;
  border-bottom: 1px solid #EBEEF5;
  margin-bottom: 12px;
}
.box-card {
    /* 头部 */
    .header{
        margin-bottom: 12px;
        /* 查询框 */
        .el-input-group__prepend .el-select {
            width: 90px;
        }
        .employees-input {
            button {
                height: 100%;
                padding: 9px 20px;
                border-radius: 0px 3px 3px 0px;
                color: #fff;
                background-color: #25334e;
            }
            button:hover {
                background-color: #182132;
            }
        }
    }
  .tree-scroll {
    max-height: 615px;
    overflow: auto;
    padding-bottom: 1px;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .brother-showing {
      i {
        width: 13px;
      }
      .iconfont {
        font-size: 10px;
        vertical-align: baseline;
      }
    }
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .active {
      button {
        height: 20px;
        padding: 3px 10px;
      }
    }
  }
  .active {
    display: none;
  }
  .action:hover .active {
    display: block;
  }
  .icon-dialog-visible {
    height: 500px;
    .icon-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #7a7b7e;
      font-size: 16px;
    }
    .icon-list:hover {
      cursor: pointer;
      background: #fff;
      border: 1px solid #ebeef5;
      height: 40px;
      padding: 3px;
      z-index: 2000;
      color: #262627;
      line-height: 1.4;
      text-align: justify;
      font-size: 30px;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      word-break: break-all;
    }
  }
}
</style>
